var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "admin-alarm"
  }, [_c('div', {
    staticClass: "top-header"
  }, [_c('div', {
    staticClass: "top-header-wrap"
  }, [_c('img', {
    staticClass: "back-button",
    attrs: {
      "src": require("@/assets/images/common/bul_arr_left_gray.svg")
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'Dashboard'
        });
      }
    }
  }), _vm._v(" 알림센터 ")])]), _c('div', {
    staticClass: "alarm-wrap"
  }, [_c('ul', {
    staticClass: "alarm-button"
  }, [_c('li', {
    class: {
      active: _vm.alaramType === 'all'
    },
    on: {
      "click": function click($event) {
        _vm.alaramType = 'all';
      }
    }
  }, [_vm._v(" 전체 알림 ")]), _c('li', {
    class: {
      active: _vm.alaramType === 'noCheck'
    },
    on: {
      "click": function click($event) {
        _vm.alaramType = 'noCheck';
      }
    }
  }, [_vm._v(" 미확인 알림 "), _vm.noCheckAlarm.totalCount !== 0 ? _c('div', {
    staticClass: "count"
  }, [_vm._v(" " + _vm._s(_vm.noCheckAlarm.totalCount) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "alarm-section"
  }, [_vm.alaramType === 'all' ? _c('div', {
    staticClass: "title"
  }, [_vm._v(" 최근 6개월 동안 받은 알림 ")]) : _vm._e(), _vm.alaramType === 'noCheck' ? _c('div', {
    staticClass: "confirm-button",
    class: {
      'confirm-button-disable': _vm.noCheckAlarm.totalCount === 0
    },
    on: {
      "click": function click($event) {
        _vm.noCheckAlarm.totalCount !== 0 ? _vm.alarmCheckAllUpdate() : '';
      }
    }
  }, [_vm.noCheckAlarm.totalCount !== 0 ? _c('img', {
    attrs: {
      "src": require("@/assets/images/admin/icon_check_alarm.svg")
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/images/admin/icon_check_alarm_grey.svg")
    }
  }), _vm._v(" 모두 확인 ")]) : _vm._e(), _c('div', {
    staticClass: "line"
  }), _c('ul', {
    staticClass: "alarm-list"
  }, _vm._l(_vm.alaramType === 'all' ? _vm.allAlarm.list : _vm.noCheckAlarm.list, function (alarm, index) {
    return _c('li', {
      key: index
    }, [_c('div', {
      staticClass: "date-wrap"
    }, [_c('div', {
      staticClass: "date1"
    }, [_vm._v(_vm._s(alarm.NewSentAt.slice(2, 7)))]), _c('div', {
      staticClass: "date2"
    }, [_vm._v(_vm._s(alarm.NewSentAt.slice(8, 10)))])]), _c('ul', {
      staticClass: "content-list"
    }, _vm._l(alarm.list, function (list, i) {
      return _c('li', {
        key: i,
        class: {
          nocheck: list.status === '미확인'
        }
      }, [_c('div', {
        staticClass: "content-wrap"
      }, [_c('div', {
        staticClass: "dotted"
      }), _c('div', {
        staticClass: "text"
      }, [_vm._v(_vm._s(list.contents))]), _c('div', {
        staticClass: "date"
      }, [_vm._v(_vm._s(_vm._f("date")(list.sentAt)))])]), _c('a', {
        attrs: {
          "href": _vm._f("notification_code")(list.code),
          "target": "_blank"
        },
        on: {
          "click": function click($event) {
            list.status === '미확인' ? _vm.alarmCheckUpdate(list.id) : '';
          }
        }
      }, [_c('div', {
        staticClass: "detail-button"
      }, [_vm._v("상세보기")])])]);
    }), 0)]);
  }), 0), _vm.alaramType === 'all' && _vm.allAlarm.page !== _vm.allAlarm.totalPage || _vm.alaramType === 'noCheck' && _vm.noCheckAlarm.page !== _vm.noCheckAlarm.totalPage ? _c('div', {
    staticClass: "more-button",
    on: {
      "click": function click($event) {
        return _vm.alarmList(_vm.alaramType);
      }
    }
  }, [_vm._v(" 더보기 + ")]) : _vm._e(), _vm.alaramType === 'all' && !_vm.allAlarm.list.length || _vm.alaramType === 'noCheck' && !_vm.noCheckAlarm.list.length ? _c('div', {
    staticClass: "alarm-list-empty"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/admin/icon_empty_alarm.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.alaramType === "all" ? "받은 알림이 없습니다." : "미확인 알림이 없습니다.") + " ")])]) : _vm._e()])]), _c('div', {
    staticClass: "footer"
  }, [_vm._v("ⓒ QPICK")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }